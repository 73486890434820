import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"
import Head from "../components/head"
import SEO from '../components/seo'
import Img from "gatsby-image"

export const query = graphql`
  query($slug: String!) {
    contentfulProducts(slug: { eq: $slug }) {
      title
      dateAdded(formatString: "MMMM Do, YYYY")
      slug
      productImage {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      body {
        json
      }
      productFile {
        file {
          url
        }
      }
    }
  }
`
const options = {
  renderNode: {
    "embedded-asset-block": (node) => {
      const alt = node.data.target.fields.title['en-US']
      const url = node.data.target.fields.file['en-US'].url
      return <img alt={alt} src={url} />
    }
  }
}

const Product = props => {
  return (
    <Layout>
      <SEO />
      <Head title={props.data.contentfulProducts.title}/>
      <h1>{props.data.contentfulProducts.title}</h1>
      <hr />
      <br />
      <p className="product-image">
        <Img fluid={props.data.contentfulProducts.productImage.fluid} alt={props.data.contentfulProducts.slug} />
      </p>
      <p class="product-details">
        {documentToReactComponents(props.data.contentfulProducts.body.json, options)}
      </p>
      <p>Download the catalogue of our product <a href={props.data.contentfulProducts.productFile.file.url} target="_blank">here</a>.</p>
    </Layout>
  )
}

export default Product
